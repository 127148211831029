import React, { ChangeEvent, FC } from "react";
import {
  StyledCheckbox,
  StyledCheckboxContainer,
  StyledHiddenCheckbox,
  StyledIcon,
} from "./index.styled";

interface CheckboxProps {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  round?: boolean;
  hasError?: boolean;
}

/**
 * HOW TO USE THE CHECKBOX COMPONENT
 * - Wrap the `<Checkbox>` inside a `<label>` to get
 *   access to the usual input interactions
 * - Common props to pass in:
 *   - checked
 *   - onChange
 *   - disabled
 *
 * **NOTE** if you use `htmlFor` on the label
 * you must have an id of the same string on
 * the `<Checkbox>`. Or don't use altogether.
 *
 * ex: `<label htmlFor="abc"><Checkbox id="abc"/></label>`
 * or simply: `<label><Checkbox/></label>`
 */
export const Checkbox: FC<CheckboxProps> = ({
  className,
  checked = false,
  disabled = false,
  hasError = false,
  round = false,
  onChange,
}) => (
  <StyledCheckboxContainer className={className}>
    <StyledHiddenCheckbox
      data-testid="checkbox-input"
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
    <StyledCheckbox
      checked={checked}
      disabled={disabled}
      round={round}
      hasError={hasError}
    >
      <StyledIcon viewBox="0 0 24 28">
        <polyline points="20 6 9 17 4 12" />
      </StyledIcon>
    </StyledCheckbox>
  </StyledCheckboxContainer>
);
